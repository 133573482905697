<script>
import NftTransfer from '@/datalayer/wallet/models/wallet/nft-transfer'

export default {
  name: 'ViewClaimables',

  data() {
    return {
      loading: false
    }
  },

  computed: {
    nfts() {
      return [
        ...this.$wallet.transfersToClaim,
        ...this.$wallet.claimables,
      ]
    },

    isEmpty() {
      return !this.nfts.length
    },
  },

  methods: {
    async init() {
      this.loading = true

      try {
        await this.$wallet.getClaimables({})
        await this.$wallet.getToClaimNft({})
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    },

    nftName(nft) {
      if(nft instanceof NftTransfer) {
        return nft.nft.saylNft.name
      }

      return nft.nft.name
    },

    nftRoute(nft) {
      if(nft instanceof NftTransfer) {
        return { name: 'sayl-connect_wallet-transfer', params: { id: nft.id } }
      }

      return { name: 'sayl-connect_wallet-claim', params: { id: nft.id } }
    },

    nftSubtitle(nft) {
      if(nft instanceof NftTransfer) {
        return this.$t('conn3ct-wallet.nft_sender', { sender: nft.fromWalletAddress })
      }

      return this.$t('conn3ct-wallet.nft_sender', { sender: nft.nft.saylAppBrand.name })
    }
  },

  mounted() {
    this.init()
  }
}
</script>

<template>
  <layout-page
    :back="{ name: 'sayl-connect_dashboard-dashboard' }"
    class="module-wallet claimables"
    :title="$t('conn3ct-wallet.claimables_title')"
    :subtitle="$t('conn3ct-wallet.claimables_description')"
    :loading="loading"
  >
    <layout-entity-list
      class="claimables__content"
      :empty="isEmpty"
      :empty-description="$t('conn3ct-wallet.empty_nft_claimables_description')"
      :empty-title="$t('conn3ct-wallet.empty_nft_claimables_title')"
    >
      <ui-card
        v-for="nft in nfts"
        class="claimables__nft"
        :key="nft.id"
        :title="nftName(nft)"
        :to="nftRoute(nft)"
        :subtitle="nftSubtitle(nft)"
      >
        <template v-slot:illustration>
          <ui-illustration
            :alt="nftName(nft)"
            fit="contain"
            :src="nft.nft.imageUrl"
            :type="nft.nft.imageType"
          />
        </template>

        <template v-slot:footer>
          <div class="claimables__nft-footer">
            <button class="claimables__nft-cta">
              {{ $t('conn3ct-wallet.view') }}
            </button>
          </div>
        </template>
      </ui-card>
    </layout-entity-list>
  </layout-page>
</template>
